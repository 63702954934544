import { NextRouter } from 'next/router';
import React from 'react';

import { PageProps } from '@tb-core/next/types';

interface ECommerceWebAppProps {
    Component: (pageProps: PageProps) => JSX.Element;
    pageProps: PageProps;
    router: NextRouter;
}

const ECommerceWebApp = ({ Component, pageProps }: ECommerceWebAppProps) => (
    <>
        <Component {...pageProps} />
    </>
);

export default ECommerceWebApp;
